.drawer {
  height: 100vh;
  box-shadow: 5px 0 4px 0 rgba(211, 211, 211, 0.5);
  overflow-y: hidden;
  overflow-x: hidden;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background: #ffffff;
  /* z-index: 1050; */
}

.drawer2 {
  height: 100vh;
  box-shadow: 5px 0 4px 0 rgba(211, 211, 211, 0.5);
  overflow-y: hidden;
  overflow-x: hidden;
  background: #ffffff;
  /* z-index: 1050; */
}
.input_field{
  height: 50px;
}

.setting-bottom {
  height: 100px;
}

.project_logo_img {
  margin-right: 20px;

  & img {
    width: 40px;
  }
}

.custom-nav-dropdown {
  position: relative !important;
}

.main-nav-container {
  /* height: 1; */
  height: calc(100vh - 30px);
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.main-nav-container::-webkit-scrollbar {
  display: none;
}

.fw-5 {
  font-weight: 500 !important;
}

.min-w {
  min-width: 240px !important;
}

.min-w2 {
  min-width: 240px !important;
}

.br-4 {
  border-radius: 4px !important;
}

.hide {
  display: none;
}

.hr {
  width: 100%;
  height: 2px;
  background: #eaecf0 !important;
}

.nav-link.active {
  background: #f97b06;
  color: #ffffff !important;
}

.primary_popover_item1:hover {
  background: #fac6a3;
  color: #ffffff !important;
}

.mobile-effect {
  position: absolute;
  background: white;
  z-index: 99;
  box-shadow: none !important;
  height: 100vh;
}

.bg-tranparen-dark {
  width: 100%;
  height: 100vh !important;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.7) !important;
  z-index: 999 !important;
}

.content {
  height: 100vh;
  overflow-y: auto !important;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: white;
}

.content2 {
  height: 100vh;
  overflow-y: auto !important;
  /* background-color: rgba(248, 248, 248, 1); */
  background-color: white;
}

.app-bar {
  width: 100%;
  height: 75px;
  position: sticky;
  top: 0;
  background: white !important;
  z-index: 2;
}

.shadow-none {
  box-shadow: none !important;
}

.page-content {
  height: calc(100vh - 76px);
  background-color: rgba(248, 248, 248, 1);
  padding: 2.25rem;
  overflow-y: auto;
}

.logo {
  display: flex;
  justify-content: center;
  padding: 0 8px;
  gap: 10.44px;
  /* background: white; */
  position: sticky;
  top: 0;
  align-items: center;
}

/* .mainLogo_text {
  color: #000;
  font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
  font-family: Inter;
  font-size: 19.76px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
 
} */

.mainLogo_text_one {
  color: #000;
  font-family: Inter;
  font-size: 13.928px;
  font-style: normal;
  font-weight: 800;
  line-height: 145%;
  /* 20.196px */
  letter-spacing: 7.382px;
}

.mainLogo_text_two {
  color: #000;
  font-family: Inter;
  font-size: 6.159px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  /* 8.93px */
  letter-spacing: 7.206px;
}


.logo1 {
  display: flex;
  padding: 0 12px;
  background: white;
  position: sticky;
  top: 0;
}

ul li {
  /* margin-bottom: 5px; */
}

ul li .nav-link {
  min-height: 40px;
  color: #101828;
  padding: 8px;
  border-radius: 8px;
}

ul li .nav-link:hover {
  background: #fac6a3;
  color: #ffffff !important;
}

.topbar-p {
  padding: 30px 40px;
}

.childrenContent {
  width: 100%;
  height: 100vh;
  overflow: auto;

}

.child_main_container {
  padding: 27px;
  padding-bottom: 0px;

}
@media (max-width:500px){
  .child_main_container {
    padding: 15px;
    padding-bottom: 0px;
  
  }
}

.sidebar_dard_container {
  background-color: #ECECEC;
  filter: drop-shadow(0px 3.513px 3.513px rgba(0, 0, 0, 0.25));
}

.decoration-none {
  text-decoration: none !important;
}

.sub-menu {
  width: 100%;
  overflow: hidden;
  -webkit-transition: all 0.5s ease !important;
  -moz-transition: all 0.5s ease !important;
  -ms-transition: all 0.5s ease !important;
  -o-transition: all 0.5s ease !important;
  transition: all 0.5s ease !important;
}

.sidenav-width {
  width: 252px;
  transition: 0.5s;
}

.logo-heading {
  font-size: 18px;
  font-weight: 700;
  color: #475467;
}

.p-relative {
  position: relative;
}

.b-none {
  border: 0.1px solid white !important;
}

.accountSetting {
  font-size: 18px;
  font-weight: 700;
  color: #475467;
}

.EvaluteLogo {
  font-family: Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #00436F;
}

.sidebar_content_container {
  height: 92%;
  overflow: auto;
  scrollbar-width: none;
}


@media screen and (max-width: 991px) {
  .page-content {
    padding: 1.75rem;
  }
}

@media screen and (max-width: 500px) {
  .page-content {
    padding: 1rem;
  }
}







/* Roles Css */

.navLink {
  text-decoration: none;
  border-bottom: none;
  font-size: 1rem !important;
}


.superadmin_dashboard_sub_navlink .navlink {
  display: flex;
  align-items: center;
  column-gap: 12px;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  font-family: "Lufga";
  margin-top: unset;
  text-align: start;
  width: 100%;
}

.superadmin_dashboard_sub_navlink .navlink svg path {
  fill: white;
}

.superadmin_dashboard_sub_navlink {
  display: flex;
  align-items: center;
  column-gap: 12px;
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  font-family: "Lufga";
  padding: 0.2rem;
  margin-top: 10px;
  background: #f97b06;
  border-radius: 10px;
  width: 100%;
}

.superadmin_dashboard_sub_navlink:hover {
  width: 100%;
}


.navlink {
  padding: 8px;
  color: #000;
  font-family: Inter;
  font-size: 13.239px;
  font-style: normal;
  margin-top: 15px;
  font-weight: 400;
  line-height: 16.549px;
  /* 125% */
  letter-spacing: 0.159px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  border-radius: 8.784px;


}



.navlink:hover {
  background: #fac6a3;
  /* color: #ffffff !important; */
  width: 100%;

}

/* .navlink:hover svg path {
  fill: white
} */


.project_headline_for_topbar {
  font-size: 1.7rem;
  margin-left: 20px;
  width: 100%;
}




.active>div {
  background: #f97b06;
  color: #ffffff !important;
}
@media (max-width: 570px) {
  .project_headline_for_topbar{
    font-size: 1.3rem;
    width: 70rem;
  }
}