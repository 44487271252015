@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

html {
  overflow: hidden;
}

.fs-14 {
  font-size: 14px;
}

.text-gray {
  color: rgba(102, 113, 133, 1);
}

.fw-500 {
  font-weight: 500;
}

.text-underline {
  text-decoration: underline;
}

.heading {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: -0.02em;
  text-align: left;
}

.overview_table_scroll{
  overflow: auto;
}
.padding_space {
  padding-left: 50px;
  padding-right: 50px;
  background-color: #ffff;
}

.btn-primary {
  background: #F97B06 !important;
  border-color: #F97B06 !important;
}

/* .p-inputtext{
  padding: 5px !important;
} */
.labels {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  /* text-align: left; */
  color: #101928;
}

.chart_head {
  background: #012a3e;
  padding: 20px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.chart_heading {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.p-button {
  background: transparent;
  color: #000;
  /* border: 1px solid #d0d5dd !important;
  border-radius: 6px !important; */
}

.Apply_filter_btn {
  /* width: 102px; */
  height: 40px;
  /* padding: 8px 15px; */
  /* padding: 8px, 12px, 8px, 12px; */
  border-radius: 8px !important;
  /* gap: 10px; */
  background-color: #f97b06;
  color: #ffffff;
}

.plain_text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
}

.select_box {
  border: none;
}

.cursor {
  cursor: pointer;
}

p {
  margin: 0px !important;
}

body {
  background: #84c2e30d;
}

h1 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

h2 {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 600;
}

.filter_btn {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: #344054;
  border: 0.5px solid #d0d5dd;
  background: none;
  border-radius: 0.5rem;
  padding: 8px 15px;
  cursor: pointer;
}

.filter_btn:disabled {
  color: #a3abb9;
  cursor: not-allowed;
}

.filter_apply_btn {
  font-size: 0.9rem;
  font-weight: 600;
  color: #ffffff;
  border: 0.5px solid #d0d5dd;
  background: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  background-color: #f97b06;
}

.clear_btn {
  font-size: 0.9rem;
  font-weight: 600;
  color: #344054;
  border: none;
  background: none;
  padding: 0.5rem 1rem;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown_box {
  /* width: 160px; */
  height: 40px;
  padding: 24px 16px;
  border-radius: 6;
  border: 1px solid #d0d5dd;
  align-items: center;
}

.statusBox {
  width: 15px;
  height: 15px;
  display: inline-block;
}

.active {
  background: #4FC1E9;
}

.onHold {
  background: #FFCE54;
}

.completed {
  background: #A0D468;
}


.draft_status {
  background: #f3a218;
}

.work_required_status,
.ready_for_review,
.void {
  background-color: #0286ff;
}

.ready_to_close_status {
  background-color: #0f973d;
}

.close {
  background-color: gray;
}

.green,
.close_status,
.close_status_obser,
.Completed,
.approved {
  background: #A0D468;
}

.yellow,
.on_hold_status,
.initiated,
.on_hold_status_obser,
.pending {
  background: #FFCE54;
}

.red,
.not_started,
.open_status_obser,
.rejected {
  background: #ED5565;
}

/* .react_table {
  .p-datatable .p-datatable-thead > tr > th {
    background: none;
  }

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: #f3f5f9;
    border-style: solid;
    border-width: 1px;
  }
} */

.disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.316);
}

.filter_btn_apply {
  font-size: 0.9rem;
  font-weight: 600;
  color: #ffffff;
  border: 0.5px solid #d0d5dd;
  background: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  background-color: #f97b06;
}

.p-datepicker-buttonbar {
  justify-content: end;

  .calendarTodayButton {
    display: none;
  }
}

.Critical_task {
  color: #FF6361;
}

.High_task {
  color: #BC5090;
}

.Neutral_task {
  color: #494CA2;
}

.body_blue {
  color: #0286FF;
}

.body_red {
  color: #ED5565;
}

.body_green {
  color: #A0D468;
}

.listbutton {
  padding: 5px 20px;
  border: 1px solid #E4E7EC;
  border-radius: 6pxpx;
  background-color: white;
}

/* .p-dropdown .p-dropdown-label.p-placeholder {
  color: #ffffff !important;
}
*/
.p-dropdown .p-dropdown-trigger {
  /* color: #ffffff !important; */
  width: 1.4rem !important;
  padding-right: 0.5rem;
  padding-top: 0.2rem;


}

.p-dropdown .p-dropdown-trigger svg {
  width: 12px;
}

.custom-steps .p-steps .p-steps-item .p-steps-title {
  display: inline-block;
}

.CustomDialog_header {
  padding: 20px 32px !important;
}


.cursorpointer {
  cursor: pointer !important;
}

.steps-content {
  height: calc(100vh - 300px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}


/* draft status  */
.statusOuterD {
  background: rgba(255, 99, 97, 0.2);
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.statusDotD {
  width: 8px;
  height: 8px;
  background: rgba(255, 99, 97, 1);
  border-radius: 100%;
}

.StatusD {
  font-size: 11px;
  color: rgba(255, 99, 97, 1);
  font-weight: 500;
  white-space: no-wrap;
}

/* inreview status  */

.statusOuterI {
  background: rgba(89, 93, 191, 0.2);
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 100%;
}

.form_header {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}

.document_table_scroll {
  height: calc(100vh - 290px);
  overflow: auto;
}

.statusDotI {
  width: 8px;
  height: 8px;
  background: rgba(89, 93, 191, 1);
  border-radius: 100%;
}

.StatusI {
  font-size: 11px;
  color: rgba(89, 93, 191, 1);
  font-weight: 500;
  white-space: no-wrap;
}

/* pending approval status  */

.statusOuterP {
  background: rgba(93, 156, 236, 0.2);
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 100%;
}

.statusDotP {
  width: 8px;
  height: 8px;
  background: rgba(93, 156, 236, 1);
  border-radius: 100%;
}

.StatusP {
  font-size: 11px;
  color: rgba(93, 156, 236, 1);
  font-weight: 500;
  white-space: nowrap;
}

/* not started status  */

.statusOuterns {
  background: rgba(172, 146, 236, 0.2);
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;

}

.radius-0 {
  border-radius: 0px !important;
}


.statusDotns {
  width: 8px;
  height: 8px;
  background: rgba(172, 146, 236, 1);
  border-radius: 100%;

}

.Statusns {
  font-size: 11px;
  color: #012A3E;
  font-weight: 500;
  text-wrap: nowrap;
}



/* in progress status  */

.statusOuterip {
  background: rgba(255, 206, 84, 0.2);
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.statusDotip {
  width: 8px;
  height: 8px;
  background: rgba(255, 206, 84, 1);
  border-radius: 100%;
}

.Statusip {
  font-size: 11px;
  color: #012A3E;
  font-weight: 500;
  text-wrap: nowrap;
}




/* complete status  */

.statusOuterc {
  background: rgba(160, 212, 104, 0.2);
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.statusDotc {
  width: 8px;
  height: 8px;
  background: rgba(160, 212, 104, 1);
  border-radius: 100%;
}

.Statusc {
  font-size: 11px;
  color: #012A3E;
  font-weight: 500;
  text-wrap: nowrap;
}



/* review status  */

.statusOuterr {
  background: rgba(79, 193, 233, 0.2);
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.statusDotr {
  width: 8px;
  height: 8px;
  background: rgba(79, 193, 233, 1);
  border-radius: 100%;
}

.Statusr {
  font-size: 11px;
  color: #012A3E;
  font-weight: 500;
  text-wrap: nowrap;
}

.add_btn {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* incomplete status  */

.statusOuterin {
  background: rgba(237, 85, 101, 0.2);
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.statusDotin {
  width: 8px;
  height: 8px;
  background: rgba(237, 85, 101, 1);
  border-radius: 100%;
}

.Statusin {
  font-size: 11px;
  color: #012A3E;
  font-weight: 500;
  text-wrap: nowrap;
}



/* approved status  */

.statusOuterA {
  background: rgba(111, 151, 92, 0.2);
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.statusDotA {
  width: 8px;
  height: 8px;
  background: rgba(111, 151, 92, 1);
  border-radius: 100%;
}

.StatusA {
  font-size: 11px;
  color: rgba(111, 151, 92, 1);
  font-weight: 500;
}





@media (max-width: 570px) {
  .stepper_tab_label {
    display: none;
  }

  .stepper_tab_icon {
    font-size: 12px;
    height: 35px;
    width: 35px;

  }

  .p-steps .p-steps-item:before {
    margin-top: 0px !important;
    /* transform: translate(38px); */
  }

  .stepper_tab_icon_translated {
    transform: translateY(0px);

  }

  .filter_btn {
    padding: 6px;
    width: 100%;
    margin-top: 0.5rem;
  }

  .p-inputtext {
    padding: 0.3rem 0.4rem !important;
  }
}