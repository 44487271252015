.borderedTable_main_con {
    /* max-width: 1350px; */
    max-height: 50vh;
    overflow: auto;
}
.borderedTable_main_con2 {
    /* min-width: 1350px; */
}

.primaryTableHeader {
    background-color: red !important;
}


/* .primary_table_maincontainer.p-datatable .p-datatable-thead>tr>th {
    background-color: red;
} */