.emailPage,
.composeEmailPage {
  .parent {
    /* margin: 2.5rem 2rem; */

    .body {
      gap: 40px;
      background: white;
      border-radius: 1rem;
      /* padding: 1.5rem; */
    }

    .header {
      gap: 1.5rem;

      .right {
        font-size: 0.9rem;
      }
    }
  }
}

/* {{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{Inbox css}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}} */
.inboxPage {
  .emailParent {

    /* .msgList{
        } */
    .left {
      .mailItem {
        /* border-right: 0.5px solid rgba(128, 128, 128, 0.335); */
        padding: 0.5rem;
        border-bottom: 0.5px solid rgba(82, 82, 82, 0.408);

        .right {
          font-size: 0.9rem;

        }

        cursor: pointer;
      }

      .active {
        background: #d0d5dd;
      }
    }

    .right {
      .box {
        border-bottom: 0.5px solid rgba(82, 82, 82, 0.408);
      }

      .exportBtn {
        font-weight: 600;
        font-size: 1rem;
      }

      .metaData {
        min-height: 1.5rem;
      }
    }

    .timeCss {
      color: black;
      font-weight: 500;
    }
  }

  .leftSideBar_css {
    border-radius: 5px;
    padding: 5px 10px;
    /* width: 50% !important; */
    border: 1px solid #e1dddd;

    .sideBarEmail {
      height: calc(100vh - 256px);
      overflow-y: auto !important;
    }
  }

}

.upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* {{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{Compose email page css}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}} */
.composeEmailPage {
  .parent {
    .body {
      .dimNav {
        color: #98a2b3;
        cursor: pointer;
      }

      .formArea {
        .recivers {}
      }
    }
  }
}

@media only screen and (max-width: 600px) {

  .leftSideBar_css {
    width: 100% !important;
  }

  .mailBody {
    display: flex;
    flex-direction: column;
  }

  .massage {
    display: unset;
  }

  .inboxPage {
    .emailParent {

      /* .msgList{
          } */
      .left {
        .mailItem {
          /* border-right: 0.5px solid rgba(128, 128, 128, 0.335); */
          padding: 10px;


          cursor: pointer;
        }

      }



    }



  }

  .emailPage,
  .composeEmailPage {
    .parent {
      /* margin: 5px; */

      .body {
        /* padding: 10px; */
      }


    }
  }
}


.single_email_card_container {
  border: 1px solid #F3F5F9;
  display: grid;
  cursor: pointer;
  grid-template-columns: 40px 1fr 150px;
  padding: 16px 24px;
}

.single_email_card_active {

  background-color: #F3F5F9;
}


.single_email_checkbox {
  display: flex;
  padding-top: 10px;
}


.single_email_title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #475367;
}

.single_email_card_content {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #353638;
  margin: 5px 0px;
}

.tag_card_con {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
}

.tag_card_item {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #353638;
  padding: 0px 8px;
  background-color: #EC87C0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 8px;

}

.single_email_card_actions {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #848484;
  padding-top: 10px;
}

.single_email_secondary_btns {
  border: 1px solid #D0D5DD;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20.3px;
  text-align: center;
  color: #344054;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.active_card {
  background-color: #F3F5F9 !important;
}

.singleEmailHeading {

  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000
}

.single_email_view_date {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #848484;
}

.single_email_view_content_basic {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #353638;
}

.all_emails_container {
  height: calc(100vh - 400px);
  overflow: auto;
}

.single_email_card_message {
  height: 25px;
  overflow: hidden;
}

.no_email_heading {
  color: #000000;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  letter-spacing: -0.02em;
  text-align: center;

}

.no_email_content {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #000000;
  margin-top: 30px
}