

.notification-container {
    padding: 40px;
    max-width: 100%;
    margin: 40px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  .notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .notification-mark-read {
    display: flex;
    align-items: center;
   
    margin-bottom: 20px;
  }
  .notification-title {
    font-size: 24px;
    margin: 0;
  }
  
  .notification-filter {
    margin-bottom: 20px;
    padding: 5px;
    border: 1px solid #D0D5DD;
    border-radius: 4px;
    color:#98A2B3;

  }
  
  .notification-group {
    margin-top: 20px;
  }
  
  .notification-group-title {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .notification-item {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    background: #fff;
  }
  
  .notification-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .notification-item-title {
    margin: 0;
    font-weight: bold;
  }
  
  .notification-item-description {
    margin: 5px 0;
    /* max-width: ; */
  }
  
  .notification-item-time {
    font-size: 0.8em;
    color: #555;
    display: flex;
    justify-content: end;
    
  }
  


  .notification-item {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    background: #fff;
    cursor: pointer;

    
  }
  
  .notification-item.unread {
    /* border-left: 5px solid #007bff; */
    background-color: #F3F5F9;

  }
  
  .notification-item.read {
   
  }

 
  .mark-all-read-button {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    text-decoration: underline !important;
    font-weight: bold;
    color: white;
    cursor: pointer;
    align-items: left;
    color: #012A3E;

    

    
  }
  
  .mark-all-read-button:hover {
   
  }

  .number-icon {

  top: -10px;
  right: -10px;
  background-color: #F97B061A;
  color: #F97B06;
  border-radius: 30%;
  width: 24px;
  /* height: 24px; */
  display: flex;
  
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  padding: 5px;

  }




  @media (max-width: 576px) {

    .notification-title {
      font-size: 18px;
      margin: 0;
    }
    .mark-all-read-button {
      font-size: 14px;
    }
    .notification-group {
font-size: 12px;

    }

    .notification-container {
      margin: 0px;
      padding: 5px;
    }
    .notification-item {
      padding: 2px;
    }
    .notification-item-description {
      line-height: 14px;
      margin: 0px;
      
    }
    .notification-item-time {
      font-size: 8px;
    }


  }