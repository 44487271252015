.p-menuitem-link {
  color: red !important;
}
a {
  /* color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)); */
  color: #344054 !important;
  text-decoration: none !important;
}

.p-menubar {
  background: none !important;
  background-color: none !important;
  border: none !important;
}
/* .p-menuitem-link {
  border-color: orange !important;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border-color: orange !important;
} */
.p-tabmenu.p-tabmenu-nav.p-tabmenuitem.p-highlight.p-menuitem-link {
  border-color: 1px solid red !important;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff !important;
  border-color: #f97316 !important;
  color: #e4eced !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border-color: #f97316 !important;
}

.ProgressBarCss {
  width: 99% !important;
}

.request_Heading {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 21px;
}

.addBorder::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.1rem;
  background-color: #d0d5dd;
  margin: 40px 0px;
}

.schedlueChangeHeading {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 21px;
  margin: 20px 0px;
}

/* calendar styles */
.tool_name{
  padding: 6px;
}

.selected_tool{
  background-color: rgba(135, 207, 235, 0.202) !important;

  border-radius: 5px;
}