.outerBox{
    height: 100%; 
}

.headingBox{
    height: 45px;
}

.dropdownBox{
    height: 60px;
}

.tableBox{
    height: calc(100% - 165px);
    overflow: auto;
}

.buttonBox{
    height: 60px;
}

@media only screen and (max-width: 560px) {
    .dropdownBox{
        height: 120px;
    }
    
    .tableBox{
        height: calc(100% - 225px);
        overflow: auto;
    }
    

}