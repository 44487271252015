c.labelCss {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #101928 !important;
}

@media only screen and (max-width: 600px) {
  .SearchInputC {
    margin-bottom: 20px !important;

  }

  .heading {
    font-size: 16px !important;

  }

  .end {
    /* margin-top: 15px; */
  }
}