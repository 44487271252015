.project_stages_form_grid_con {
    /* border: 1px solid red; */
    display: grid;
    align-items: center;
    gap: 20px;
    row-gap: 20px;
    grid-template-columns: 20px 1fr 1fr 1fr 200px;

    
}
@media (max-width: 570px) {
    .project_stages_form_grid_con {
        grid-template-columns: auto

    }
}


