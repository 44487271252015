.client_request_form_bullet_points {
  display: flex;
  align-items: center;

  .bullet_point {
    background-color: #f3771c;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #ffffff;
  }
  .bullet_point_text {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    margin-left: 10px;
  }
}
