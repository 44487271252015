.post_form_item {
    /* border: 0.5px solid rgba(128, 128, 128, 0.198); */
    border-radius: 4px;
    padding: 40px;
    position: relative;

    & label {
        margin-bottom: 2px;
    }

    .cross_icon {
        position: absolute;
        top: -12px;
        right: -12px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f97b06;
        border-radius: 50%;
        padding: 10px;
        cursor: pointer;
        color: white;
    }

}

.address_listing_container {
    min-height: calc(100vh - 80px);
    & label{
        color: #101928 !important;
        font-weight: 600;
    }

}
.p-accordion-header-link{
    background-color: white !important;
    /* color: skyblue !important; */
}

.create_address_label{
font-family: Inter;
font-size: 14px;
font-weight: 600;
color: rgba(16, 25, 40, 1);

}
.disableActionBtn:disabled{
    color: rgba(208, 213, 221, 1) !important;
    border: 1px solid rgba(208, 213, 221, 1);
    background-color: white !important
}