.customInputDragAndDrop_images_flex_con {

    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    gap: 10px;


}

.drop_image_con {
    width: 100px;
    height: 100px;
    /* border: 1px solid red; */
    position: relative;
    border: 1px solid grey;
    border-radius: 10px;
    overflow: hidden;
}
.drop_remove_icon{
    position: absolute;
    background-color: white;
    top:-1px;
    right: -1px;
    border: 1px solid grey;
    border-radius: 50%;
    width: 20px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 20px;
    z-index: 999;
}

.drop_image_con img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}