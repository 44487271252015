.direct_cost_general_table_scroll {
    height: calc(100vh - 380px);
    overflow: auto;
}

.grand_total_contaier {
    height: 56px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    background-color: rgba(247, 249, 249, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 50px;
    font-weight: 600;
    
}