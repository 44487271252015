.main_container {
  height: 100%;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* width: 100%; */
}

.main_container_upper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.main_container_upper_left {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 35%;
}

.main_container_upper_left_upper {
  background: white;
  border-radius: 12px;
  box-shadow: 4px 4px 20px 0px #0000000a;
  padding: 1rem;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.1px;
  text-align: left;
}

.main_container_upper_left_lower {
  background: white;
  border-radius: 12px;
  box-shadow: 4px 4px 20px 0px #0000000a;
  padding: 1rem;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.1px;
  text-align: left;
}

.main_container_upper_right {
  background: white;
  border-radius: 12px;
  box-shadow: 4px 4px 20px 0px #0000000a;
  width: 65%;
  padding: 1rem;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.1px;
  text-align: left;
}

.main_container_lower {
  background: white;
  border-radius: 12px;
  box-shadow: 4px 4px 20px 0px #0000000a;
  padding: 1rem;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.1px;
  text-align: left;
}






@media only screen and (max-width: 1500px) {
  .main_container_upper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .main_container_upper_left {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
  }


  .main_container_upper_left_upper {
    width: 50%;
  }

  .main_container_upper_left_lower {
    width: 50%;
  }

  .main_container_upper_right {
    background: white;
    border-radius: 12px;
    box-shadow: 4px 4px 20px 0px #0000000a;
    width: 100%;
    padding: 1rem;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 26.1px;
    text-align: left;
  }


}



@media only screen and (max-width: 860px) {
  .main_container_upper_left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .main_container_upper_left_upper {
    width: 100%;
  }

  .main_container_upper_left_lower {
    width: 100%;
  }



}


.overview_main_container {
  /* padding: 22px 35px; */
  .project_tool_tab {
    background-color: #EEE;
    width: fit-content;
    border-radius: 8px;
    padding: 5px 10px;
    font-family: Inter;
    cursor: pointer;
    font-weight: 500;
  }
  .project_tool_active_tab{
    background-color: #FF7B00;
    color: #FFF;
  }
}