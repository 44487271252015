.Model_section {
  background-color: #ffff;
  margin: 40px 32px;
  padding: 24px;
  border-radius: 20px;
  /* border: 1px solid red; */
}

.model_card {
  /* border: 1px solid red; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* border: 1px solid green; */
  width: 20%;


}

.model_img {
  width: 100%;
}

.coordination {
  margin-top: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border-radius: 3px;
}

.dataheading {
  font-family: Inter;
  font-size: 14px;
  color: #012A3E;
  font-weight: 400;
}

.TitleHeading {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.1px;
  text-align: left;
  color: #012A3E;

}

.photos {
  .card {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  }

  .photo-card {
    /* width: 22%; */
    min-width: 300px;
    overflow: hidden;
    height: 19rem;
    border-bottom: 1px solid lightgray;
  }

  .album-photos {
    box-shadow: 1px 1px 1px black;
    /* height: 15rem; */
    overflow: hidden;

    img {
      max-height: 14rem;
      overflow: hidden;
      width: 100%;
    }
  }

  .card-title {
    font-size: 16px;
    font-weight: bold;
  }

  .card-text {
    font-size: 14px;
    color: gray;
  }

}

.album-header {
  border-bottom: 1px solid lightgray;
}

.ImgHeight {
  height: 200px;
  width: 90%;
}

.ActiveText {
  position: relative;
}

.SubActivetext {
  position: relative;
  padding: 4px 16px;
  border-radius: 8px 0 0 0;
  bottom: 25px;
  color: #012A3E;
  left: 71.5%;
  background-color: #FFCE54;
}

.SubActivetext2 {
  position: relative;
  padding: 4px 16px;
  border-radius: 8px 0 0 0;
  bottom: 25px;
  color: #012A3E;
  left: 71.5%;
  background-color: #A0D468;
}

.SubActivetext3 {
  position: relative;
  padding: 4px 16px;
  border-radius: 8px 0 0 0;
  bottom: 25px;
  color: #012A3E;
  left: 71.5%;
  background-color: #4FC1E9;
}

@media only screen and (max-width: 600px) {
  .Model_section {
    margin: 40px 10px;
    padding: 15px;
  }

  .model_card {
    width: 45%;
  }

}


.projectHomeListing_container {



  @media (max-width:1250px) {
    .tab_card_header_text {
     font-size: 14px;
     text-wrap: nowrap
      ;
    }

    .tab_card_value_text {
      font-size: 18px;
    }
    .tab_card{
      height: 70px;
      margin-bottom: 20px;
    }
  }
}