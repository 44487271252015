.days_left {
    font-family: Inter;
    font-size: 40px;
    font-weight: 500;
    line-height: 58px;
    text-align: left;
    color: #F97B06;
}

.expected_date{
    font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.012em;
text-align: left;
color: #101828;

}