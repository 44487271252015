/* .check-box{
    
}

.borderedTable_borderedTable_main_con__GXvE6 {
    .p-datatable-row-expansion{
    & td{
        padding: 0 !important;
        tr{
            td{
                padding: 1rem !important;
            }
        }
    }}} */
    @media (max-width: 570px) {
        .responsive_css
        {
           flex-wrap: wrap;
           gap: 1rem;
        }
    }