.coordination {
  .p-tabview .p-tabview-panels {
    padding: 0;
  }
  .arrow-down {
    color: #f97b06;
    font-size: 1rem;
  }
  .add-filter {
    border: none;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
  }
  .down-link {
    padding-bottom: 2px;
  }

    
  

}

    .open{
      
        border: none;
        background: rgb(228, 237, 240);
        border-radius: 10px;
        color: rgb(21, 130, 233);
        font-size: 12px;
        font-weight: bold;
      }
      .heading1{
        font-size: 16px;
        font-weight: bold;
      }
      .describe{
        font-size: 15px;
      }
      .coordinate{
        font-size: 18px;
        color: rgb(128, 123, 123);
        font-weight: bold;
      }
      .image{
        width: 200px;
        height: 150px;
        border-radius: 10px;
      }
      .blue-color{
        color: rgb(34, 158, 241);
      }
      .arrow-left{
        color: black;
        
      }
      .edit-btn{
        font-weight: bold;
      }
      @media only screen and (max-width: 600px) {
      .card{
        width: 100% !important;
      }
      .p-dialog-content{
        /* padding: 0 !important; */
      }
        
        }
