.searchInput_main_con {
  position: relative;
  display: flex;
  align-items: center;

  & input {
    border-radius: 12px;
    border: 2px solid #FF7B00;
    border-top: none;
    width: 100%;
    height: 38px;
    background: #FFFEFE;
    box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.11);
    padding-left: 40px;
    padding-right: 40px;
    color: #A4A1A1;
    font-family: Inter;
    font-size: 19.9px;
    font-style: normal;
    font-weight: 700;
    line-height: 28.201px;
    /* 141.717% */
    letter-spacing: -0.557px;

    &::placeholder {
      color: #A4A1A1;
      font-family: Inter;
      font-size: 19.9px;
      font-style: normal;
      font-weight: 700;
      line-height: 28.201px;
      /* 141.717% */
      letter-spacing: -0.557px;
    }

    &:focus {
      outline: none;
      /* border: 1.4px solid red; */
    }
  }


  .search_icon {
    position: absolute;
    top: 3px;
    font-size: 17px;
    left: 10px;
  }

  .cross_icon {
    position: absolute;
    top: 1.5px;
    right: 5px;
    cursor: pointer;
  }
}
















.dragAndDrop_main_con,
.dragAndDrop_main_con1 {
  min-height: 65px;
  border-radius: 6px;
  border: 2px dashed #d0d5dd;
  padding: 0px 10px;
  color: #98a2b3;

  text-align: start;

  .file_main_con {
    width: 100px;
    height: 100px;
    border-radius: 6px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 2px grey;
    position: relative;
  }

  .remove_icon {
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
  }

  .file_main_con1 {
    width: 100px;
    height: 30px;
    border-radius: 6px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 2px grey;
  }
}

.boxes {
  padding-top: 15px !important;
}

.dragAndDrop_main_con1 {
  min-height: 65px;
  border-radius: 6px;
  border: 2px dashed #d0d5dd;
  padding: 0px 10px;
  color: #98a2b3;

  text-align: start;
  overflow-y: scroll;
}

.file_main_con1 {
  /* width: 100px;
  height: 30px;
  border-radius: 6px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 2px grey; */
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 3px;
  margin-bottom: 2px;
  width: calc(33.33% - 10px);
  /* Equal width for 3 files with margins */
  text-align: center;
  justify-content: center;
  align-items: center;
}