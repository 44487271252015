.createHeadNameBtn {
  color: white;
  border-radius: 5px;
  padding: 14px;
  text-align: center;
  background: #f97b06;
  cursor: pointer;
}

.selectContainer{
  width: 98%;
}

.outerBox{
  height: 100%; 
}

.headingBox{
  height: 50px;
}

.tableBox{
  height: calc(100% - 110px);
  overflow: auto;
}

.buttonBox{
  height: 60px;
}

@media (max-width: 999px) {
  .createHeadNameBtn {
    font-size: 0.8rem !important;
  }
}
