
.pagination_main_con{
height: 37px;
.pagination-container {
    display: flex;
    list-style-type: none;
    height: 37px;
    .pagination-item {
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        color: #646464;
        font-family: Inter;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background-color: #FFF;
        margin-inline: 5px;
        box-shadow: 2px 1px 8px rgb(0, 0, 0, 0.25);

        &.dots:hover {
            background-color: transparent;
            cursor: default;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
            cursor: pointer;
        }

        &.selected {
            background-color: #F97B06;
            color: #FFF;
        }


        .arrow {
            &::before {
                position: relative;
                /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
                content: '';
                /* By using an em scale, the arrows will size with the font */
                display: inline-block;
                width: 0.4em;
                height: 0.4em;
                border-right: 0.12em solid rgba(0, 0, 0, 0.87);
                border-top: 0.12em solid rgba(0, 0, 0, 0.87);
            }

            &.left {
                transform: rotate(-135deg) translate(-50%);

            }

            &.right {
                transform: rotate(45deg);
            }
        }

        &.disabled {
            pointer-events: none;

            .arrow::before {
                border-right: 0.12em solid rgba(0, 0, 0, 0.43);
                border-top: 0.12em solid rgba(0, 0, 0, 0.43);
            }

            &:hover {
                background-color: transparent;
                cursor: default;
            }
        }
      

    }
    @media (max-width: 570px) {
        .pagination-item {
          height: 25px;
          width: 25px;
          font-size: 14px;
        }
        .change_arrow_icon {
            height: 20px !important;
            width: 20px !important;
            font-size: 10px !important;
            justify-content: center !important;
            align-items: center !important;
            margin-top: 5px;

        }

    }

    .change_arrow_icon {
        color: #909090;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-inline: 9px;
        font-size: 25px;
        transform: translateY(-4px);
    }

    .selectDropDownIcon {
        background-color: black !important;
        color: white !important
    }
}
    
}
    
    
.selectedValue{
    border: 1px solid red !important;
}

.custom-dropdown .p-dropdown-label {
    padding: 0.5em;
    line-height: 1.5em; 
    display: flex;
    align-items: center; 
}

.custom-dropdown .p-dropdown {
    height: 2.5em; 
    display: flex;
    align-items: center;
}

.custom-dropdown .p-dropdown-item {
    padding: 0.5em; 
    line-height: 1.5em;
    display: flex;
    align-items: center;
}

