.email_label {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.2px;
    text-align: left;
    color: #475367;
}

.email_data_value {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 23.2px;
    text-align: left;
    color: #101928;
}

.table_cell {
    padding: 10px 24px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #353638;
}

.table_scroll_distribution_list {
    height: calc(100vh - 330px);
    overflow: auto;
}

.table_cell {
    border: 1px solid #ECEEF2;
}

.description_text {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 23.2px;
    text-align: left;
    color: #101928;
}

.user_table_scroll {
    height: 300px;
    overflow: auto;
}