.card-add {
  .p-inputtext {
    width: 100% !important;
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    color: #4b5563;
    background: #ffffff;
    padding: 0.75rem 0.75rem;
    /* border: 1px solid #d1d5db; */
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
  }

}

.timeInputContainer .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root{
  height: 50px;
}


