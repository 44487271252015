.darken_border {
  background-color: grey;
}

.view_meeting_con {
  & table {
    border-spacing: 0 !important;
    row-gap: 0px !important;
    column-gap: 0px !important;
  }
}

.edit_meeting_con {
  @media (min-width: 767.5px) {
    .editor_row {
      padding-left: 5.5% !important;
    }
  }
}

.createHeadNameBtn {
  color: white;
  border-radius: 5px;
  padding: 14px;
  text-align: center;
  background: #f97b06;
  cursor: pointer;
}
.CreatMeeting{
  width: 33%;
}
@media only screen and (max-width: 500px) {
  .CreatMeeting{
    width: 150px !important;
  }
}