.main-container {
  input{
    width: 100%;
  }
  .open_Rfi_text {
    font-size: 0.8rem;
   
    color: #98a2b3;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
  }

  .createNew_text {
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 20px;
    color: #012a3e;
    cursor: pointer;
  }

  .newRfi_text {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 28px;
    color: #000000;
  }

  .labelCss {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #101928 !important;
  }

  .btn_main {
    display: flex;
    justify-content: end;
    gap: 10px;
    align-items: center;
    margin-top: 10px;
  }

  .CreateRfi {
    padding: 8px, 12px, 8px, 12px;
    color: white;
    background-color: #f97b06 !important;
    border-radius: 8px;
    cursor: pointer;
  }

  .cancelBtn {
    color: black;
    /* padding: 5px 10px; */
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #d0d5dd;
  }

  .attText {
    text-decoration: underline;
  }

  .p-button {
    border-radius: 0px 6px 6px 0px !important;
    background: transparent;
    color: #000;
    border: 1px solid #d0d5dd;
  }

  .add_class{
        font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #101928 !important;

  }
}

.css_com {
  .p-inputtext {
    border-style: dashed solid !important;
    width: 100% !important;
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    color: #4b5563;
    background: #ffffff;
    padding: 0.75rem 0.75rem;

    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
  }
}
