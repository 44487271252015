.rbacManagement_container {
    max-width: 1000px;
    /* margin: auto; */


    .parentModule_heading {
        font-size: 20px;
        width: 400px;
        font-weight: 600;
    }

    .parentModule_description {
        font-size: 14px;
        color: grey;
        font-style: italic;
        font-weight: 100;
    }

    .childModule_heading {
        width: 400px;
    }
}

.rbac_table_scroll {
    height: calc(100vh - 345px);
    overflow: auto;
}
.roleManagement_table_scroll{
    height: calc(100vh - 320px);
    overflow: auto;
}
@media (max-width: 570px) {
    .roleManagement_table_scroll{
        height: calc(100vh - 360px);
    }
}