.portfoli_main {
  /* border: 1px solid red;
    margin-left: 30px;
    margin-right: 30px; */

  background-color: #ffff;
  margin: 40px 32px;
  padding: 24px;
  border-radius: 20px;

  .input_box {
    width: 375px;
    height: 36px;
    padding: 8px, 12px, 8px, 12px;
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    padding-left: 35px;
  }

  .filter_Button {
    height: 36px;
    padding: 8px, 8px, 16px, 16px !important;
    border-radius: 8px;
    border: 1px solid;
    gap: 10px;
    color: #344054;
    text-align: center;
    background: transparent;
  }

  .search_icon {
    left: 15px;
    top: 12px;
  }

  /* .chart_head {
        background: #012A3E;
        padding: 20px;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;

    }

    .chart_heading {

        font-family: Inter;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;

    } */

  /* span {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #101928;

    } */
  .status {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #475367;
  }
  .check_value {
    font-family: Inter !important;
    font-size: 14px;
    font-weight: 600 !important;
    letter-spacing: 0em;
    text-align: left;
    color: #101928 !important;
  }
  .campanil_row {
    display: flex;
    /* column-gap: auto;
    grid-template-columns: auto auto auto; */
    border: 1px solid #eceef2;
    justify-content: space-between;
  }

  .chart_section {
    border: 1px solid #e4e7ec;
    border-radius: 16px;
  }
  .chart_total {
    
      width: 5rem;
      gap: 20px !important;
  
  }
  .no_gap {
    /* border: 1px solid #ECEEF2; */
    width: 100%;
  
    text-align: center;
  }
  .horizontalGraph {
    width: calc(100% - 12rem);
  }

  /* Dialog{
        z-index: 1000 !important;
        background: cyan !important;
    } */
    .chart_total_home {
      width: 5rem;
      gap: 20px !important;
  }
  .GapChart {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 20px;
}

}
@media only screen and (max-width: 600px) {
  .SearchInputC{
    margin-bottom: 20px !important;
   
  }
  .campanil_row {
    display: flex;
    flex-direction: column;
  }
  .portfoli_main{
    padding: 0px;
    margin: 0px 15px;
  }
  .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center, .apexcharts-legend.apx-legend-position-top.apexcharts-align-center{
    display: flex;
    justify-content: start !important;
  }
   

  .totalOpen {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.GapChart {
    margin-top: 45px !important;
    gap: 19px !important;
}
.flexCustom > div{
  flex: 1;

}
  }