body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mainContainer {
  width: 100%;
  padding: 2rem;
  /* height: calc(100vh - 70px); */
  /* overflow-y: hidden; */
  /* overflow: auto; */
}

.card {
  border-radius: 16px;
  border: none !important;
  color: rgb(0, 128, 0);
}

.subNav {
  font-size: 14px !important;
}

.flex {
  display: flex;
}

.between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.p-dropdown-item-label {
  width: 100%;
}

.between23 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.align_center {
  align-items: center;
  display: flex;
}

.end {
  display: flex;
  justify-content: end;
}

.expoeUnderline {
  /* text-decoration: underline; */
  border-bottom: 1px solid #101828;
}

.hr {
  width: 100%;
  height: 2px;
  background: #eaecf0 !important;
}

.clearAll {
  text-decoration: underline;
  display: flex;
  align-items: center;
}

.common_input {
  height: 50px;
}
.phoneErrorInput input{
border:1.5px solid red !important
}

.btn-secondary {
  border: 1px solid #d0d5dd !important;
  color: #344054 !important;
  background-color: #ffffff !important;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow{
  height:200px
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  z-index: 1;
  display: block;
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: white !important;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #f97b06;
  /* border-width: 0 0 3px 0 !important; */
  z-index: 3;
}

.p-tabview-nav {
  box-shadow: none !important;
}

.p-tabview .p-tabview-nav {
  background: #ffffff;
  /* border: 1px solid #e5e7eb;  */
  border-width: 0 0 0px 0 !important;
}

.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #f97b06 !important;
}

.p-progressbar {
  /* background-color: #f97b06; */
}

.p-progressbar-label {
  /* border: 1px solid #f97b06 !important;
  background-color: #f97b06 !important; */
}

.btn-Secondary {
  background-color: #ffffff !important;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd !important;
  color: #344054 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  padding: 8px 12px !important;
}

.rbc-toolbar button {
}

/* .rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: #fead07 !important;
  height: 50px !important;
} */

.addBorder_bottom {
  border-bottom: 1px solid #edecec !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #f97b06 !important;
}

.p-progressbar-determinate .p-progressbar-label {
  display: none;
}

.labelCss {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #101928 !important;
}

.p-progressbar {
  border: 0 none;
  height: 1rem;
  background: #e5e7eb;
  border-radius: 6px;
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled) {
  background: #f97b06;
  color: #ffffff;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #f97b06;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #f97b06;
  background: #f97b06;
  outline: none !important;
  box-shadow: none !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #55555500;
  border-color: none;
}

.p-button {
  border: none;
}

.p-button {
  background: transparent;

  border: none !important;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 1rem;
}

.p-overlaypanel:after,
.p-overlaypanel:before {
  left: calc(var(--overlayArrowLeft, 0) + 0.15rem);
}

/* .p-datatable .p-datatable-footer {
  background: white !important;
  background-color: white;
  font-size: 14px;
  font-weight: 400;
  color: #353638;
  border: 1px solid #eceef2 !important;
  padding: 1rem 1rem;
  font-weight: 400 !important;
} */

.dot-btn {
  padding: 0;
  border: none !important;

  .p-button-label {
    font-weight: 400 !important;
  }
}

.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-button {
  border: none;
}

.p-button:focus {
  box-shadow: none;
}

.p-button:not(:disabled):active {
  background: none;
  color: #ffffff;
  border-color: none;
}

.p-dropdown-item:hover {
  background-color: #f8f9fa;
  color: #f97b06;
  cursor: pointer;
}

.pointer {
  cursor: pointer !important;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 0 !important;
}

.Validation {
  border: 1px solid rgb(255, 114, 114) !important;
  border-radius: 6px !important;
}

.p-calendar {
  border: 1px solid #d0d5dd;
  border-radius: 6px;

  & input,
  & button {
    border: none !important;
  }
}

/* 
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #000000;
  background: #fead6259;
} */
.p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 2.5rem !important;
  height: 2.5rem !important;
  align-items: center;
}

.p-paginator .p-paginator-pages {
  align-items: center !important;
  display: flex !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  /* background: #fead627f;
  border-color: #fead6259; */
  border-radius: 50px !important;
  color: #000000;
  font-size: 14px;
  padding: 0 !important;
}

.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  height: 2.5rem;

  .p-dropdown-label {
    padding: 7px 12px;
  }
}

.p-inputswitch.p-highlight .p-inputswitch-slider {
  background: #f97b06 !important;
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover).p-highlight
  .p-checkbox-box {
  border-color: #f97b06;
  background: #f97b06;
  color: #ffffff;
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:focus-visible)
  .p-checkbox-box {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border-color: #f97b06;
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
  border-color: #d1d5db;
}

.p-checkbox.p-highlight .p-checkbox-box {
  border-color: #f97b06;
  background: #f97b06;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  border: 1px solid #f97b06 !important;
  background: white !important;
  color: #f97b06 !important;
}

.project_name_text {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 24px;
  color: #000000;
}

.p-treeselect-panel
  .p-component
  .p-ripple-disabled
  .p-connected-overlay-enter-done {
  margin-top: 20px !important;
}

.p-tree-container {
  margin: 0 !important;
  padding: 0 !important;
}

.p-treenode-children {
  margin: 0 !important;
  padding: 0 !important;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 700;
  color: #f97b06 !important;
}
.p-multiselect-panel {
  z-index: 99999 !important;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #f97b06;
  border: 1px solid #f97b06;
  background: #ffffff;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #012a3e;
  font-size: 0.9rem;
  font-size: 500 !important;
}

/* Set a fixed width for each step item */
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  text-wrap: balance !important;
  margin-top: 0.5rem;
  color: #012a3e;
  font-size: 0.9rem;
  font-size: 500 !important;
  text-align: center !important;
}

.p-column-header-content {
  .p-checkbox {
    display: none;
  }
}

.border_box {
  border-top: 1px solid #eceef2;
  border-right: 1px solid #eceef2;
  border-left: 1px solid #eceef2;
}

.add_icon_text {
  color: #344054;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.add_text {
  color: #344054;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
}

.deleteIconColor {
  color: #f97b06 !important;
  margin-top: -2px;
  cursor: pointer;
}

.delete_main_text {
  cursor: pointer;

  .deleteText {
    color: #344054 !important;
    text-decoration: underline;
    font-size: 14px;
  }
}

ul .p-dropdown-items {
  padding-left: 0px !important;
}

ol,
ul {
  padding-left: 0 !important;
}

/* Adjust the scrollbar width and border radius */
.p-dropdown-items-wrapper {
  ul {
    overflow-y: auto;
    max-height: 200px;
    padding: 0 !important;
  }

  /* Adjust scrollbar width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Adjust scrollbar track */
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 3px;
  }

  /* Adjust scrollbar handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
}

.dragAndDrop_main_con2 {
  border: 2px dashed rgb(255, 114, 114) !important;
}

.view_page_name_card {
  border: 1px solid #f97b06;
  width: fit-content;
  border-radius: 8px;
  padding: 4px 12px;
}

.view_page_para_head {
  font-weight: 400;
  color: #475367;
  font-family: Inter;
}

.view_page_bold_text {
  font-weight: 500;
  color: #101928;
  font-family: Inter;
}

.PhoneInputInput {
  border: 1px solid #d1cece;
  border-radius: 5px;
  padding: 14px;
}

/* .borderedTable_borderedTable_main_con__Gd3oH { */
/* &td { */
/* .p-datatable-tbody {
  .p-datatable-row-expansion {
    td {
      padding: 0 !important;

      tr {
        td {
          padding: 1rem !important;
        }
      }
    }
  }
} */

.open_status {
  background-color: #ed5565 !important;
}

.close_status {
  background-color: #a0d468;
}

.reopen_status {
  background-color: blue;
}

.on_hold {
  background-color: #ffce54 !important;
}

@media only screen and (max-width: 600px) {
  .end {
    display: flex;
    /* justify-content: center; */
  }

  .between23 {
    flex-direction: column;
    gap: 15px;
  }

  .SearchInputC {
    margin-bottom: 12px;
  }

  .mainContainer {
    padding: 10px !important;
  }

  .heading {
    font-size: 16px !important;
    padding-right: 8px;
  }

  .primararyButton {
    font-size: 12px !important;
  }

  .Apply_filter_btn {
    padding: 2px 15px !important;
  }

  ._CZjuD {
    overflow: unset !important;
  }
}

/* } */
/* } */

/* .borderedTable_borderedTable_main_con__jFf2w {
  .borderlessTD {
    border: none !important;
  }
} */

.tabView {
  .tablePanel {
    padding: 0 !important;
    border: 1px solid red;
  }
}

.p-tabview .p-tabview-panels {
  padding: 0px !important;
}

.twoColumnFormGridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  column-gap: 70px;

  & label {
    margin-bottom: 2px;
  }
}
@media (max-width: 570px) {
  .twoColumnFormGridContainer {
    column-gap: 10px;
  }
  .page_heading {
    font-size: 25px !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dialog_header {
  border-bottom: 1px solid rgba(208, 213, 221, 1);
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 29px;
  text-align: left;
  color: rgba(16, 25, 40, 1);
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.chart_heading {
  color: rgba(1, 42, 62, 1) !important;
  font-family: Inter;
  font-size: 18.31px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  /* 26.549px */
}

.searchInput {
  max-width: 350px;
}

.table_head_item {
  color: #757575 !important;
  font-family: Inter;
  font-size: 15.723px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.023px;
  letter-spacing: 0.189px;
}

.p-datatable .p-datatable-thead > tr > th {
  position: sticky;
  top: 0;
  background-color: white;
  color: #757575 !important;
  font-family: Inter;
  font-size: 15.723px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.023px;
  letter-spacing: 0.189px;
}

.p-datatable .p-datatable-tbody > tr > td {
  color: var(--Fonts-Primary, #25213b);
  font-family: Inter;
  font-size: 14.802px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.p-datatable .p-datatable-tbody > tr {
  border-bottom: 1.057px solid #d9d5ec;
}

.page_heading {
  color: #012a3e;
  font-family: Inter;
  font-size: 33.391px;
  font-style: normal;
  font-weight: 500;
  line-height: 41.739px;
  /* 125% */
  letter-spacing: -0.601px;
}

.table_scroll {
  height: calc(100vh - 440px);
  overflow: auto;
}

.tools_table_scroll {
  height: calc(100vh - 300px);
  overflow: auto;
}
@media (max-width: 570px) {
  .tools_table_scroll {
    height: calc(100vh - 387px);
    overflow: auto;
  }
}

.userManagement_table_scroll {
  height: calc(100vh - 325px);
  overflow: auto;
}

.custom-overlay-panel .p-overlaypanel-arrow {
  display: none !important;
}

.p-overlaypanel:after,
.p-overlaypanel:before {
  display: none !important;
}

.stepper_tab_icon {
  width: 40px;
  font-size: 25px;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 9999; */
  border-radius: 50%;
  background-color: white;

  border: 1px solid rgba(250, 156, 68, 1);
}

.stepper_tab_icon_translated {
  transform: translateY(13px);
}

.text_500_16 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: rgba(42, 42, 55, 1);
}

.stepper_tab_icon_active {
  width: 67px;
  height: 67px;
  color: white;
  font-size: 30px;
  transform: translateY(-0px);
  background-color: rgba(250, 156, 68, 1);
}

.stepper_tab_label {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20.3px;
  text-align: center;
  color: rgba(1, 42, 62, 1);
  margin-top: 10px;
}

.stepper_tab_label_active {
  color: rgba(250, 156, 68, 1);
}

.heading_500_33 {
  font-family: Inter;
  font-size: 33.39px;
  font-weight: 500;
  line-height: 41.74px;
  letter-spacing: -0.018em;
  text-align: left;
  color: rgba(1, 42, 62, 1);
}

.p-steps .p-steps-item:before {
  border: 2px solid rgba(204, 212, 216, 1) !important;

  /* border: 2px solid transparent !important */
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mp-0 {
  margin: 0;
  padding: 0;
}

.css-clttge-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 5px !important;
}
.css-2dvya8 .MuiOutlinedInput-root {
  padding: 5px !important;
}

.timeSelect_item {
  color: black;
  cursor: pointer;
}


/* ._3ZbQT div :nth-child(3){
  border:2px solid green !important ;
  display: none;
} */

._34SS0{
  border: 2px solid red !important;
}