/* .MainContainer{
    padding: 10px ;
    background-image: url('/public/images/signin.png');
    background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content:start ;
} */
.maincontainer {
  width: 100%;
  height: 100vh;
  background: url('/public/images/signin.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100vh;
  overflow: hidden;
  display: flex;
  position: relative;
}

/* .logoFixed {
  position: absolute;
  top: 70px;
  left: 60px;

} */

.eLogo {
  font-family: Helvetica;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #1a1717;
}

.right-contain {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-content-div {
  width: 60%;
}

.continueButton {
  background-color: #ff6b00;
  border-radius: 12px;
  border: none;
  padding: 10px 0;
  color: white;
}

.googleLoginButton {
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  border: none;
  padding: 0px 0;
  /* color: white; */
}

.reset_user_name {
  color: #ff6b00;
}

.reset_name_con {
  font-size: 14px;
  font-family: Helvetica;
}

.nsm7Bb-HzV7m-LgbsSe-MJoBVe {
  min-width: 100vw !important;
}

.logIn {
  font-family: Helvetica;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #ff6b00;
}

.label {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #353638;
}

.form-password {
  position: relative;
}

.password-eye {
  position: absolute;
  top: 63%;
  left: 93%;
}
.microsoft_login_btn {
  background-color: black;
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
  padding: 5px 30px;
}
.forgot-password {
  color: #ff6b00;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
}

.resetPasswordFirst {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: center;
}

.backTologin {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #ff6b00;
}

.password-container {
  width: 100%;
  border-left: 5px solid #34b53a;
  padding: 2px 10px;
  /* margin-right: 70px !important; */
}

@media screen and (max-width: 1800px) {
  .maincontainer {
    width: 100%;
    height: 100vh;
    /* background: url('/public/images/signin.png'); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    display: flex;
    position: relative;
  }
}

@media screen and (max-width: 1050px) {
  .maincontainer {
    width: 100%;
    height: 100vh;
    background: unset;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    display: flex;
  }

  .right-contain {
    align-items: center;
    width: 100% !important;
  }

  .right-content-div {
    width: 87%;
  }

  .eLogo {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .left-content {
    display: none;
  }

  .right-contain {
    align-items: center;
    width: 100% !important;
  }

  .right-content-div {
    width: 87%;
  }
}

@media screen and (max-width: 600px) {
  .maincontainer {
    width: 100%;
    height: 100vh;
    background: unset;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    display: flex;
  }

  .left-content {
    display: none;
    width: 0;
  }

  .right-content-div {
    width: 87%;
  }

  .right-contain {
    align-items: center;
    /* width: 500%; */
    /* margin-top: 225px; */
  }
}

@media screen and (max-width: 375px) {
}

@media (min-height: 570px) {
  .logoFixed {
    /* position: absolute;
    top: 70px;
    left: 60px; */
    display: none;
  }
}

@media (max-height: 570px) {
  .logoFixed {
    /* position: absolute;
    top: 30px;
    left: 130px; */
    display: none;
  }
}

/* @media (min-height: 570px) {
  .Login_Responsive{
    max-height: 100px;
    border: 1px solid red !important;
  }
} */
.verifyAccount_container {
  height: 100vh;
  display: flex;
}

.verify_account_right_con {
  width: 60%;
  height: 100%;
  background-image: url('/public/images/signin.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.verify_account_left_con {
  width: 40%;
  height: 100%;
}

.verify_account_logo {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
