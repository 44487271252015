/*=================={{{{{{{{{{{massage component css}}}}}}}}}}}==================*/
.massage_con {
  .parent {
    border-left: 4px solid #57be7d;
    padding: 20px;
    border-radius: 8px;
    background: #57be7d12;
    position: relative;
  }

  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
  }

  p,
  h3 {
    margin: 0;
  }
}

.popover_container {
  min-width: 120px !important;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0.8rem !important;
}

.umderline_dropdown_container {
  width: 100%;

  .p-dropdown {
    background: #ffffff;
    border: none;
    /* border: 1px solid #d1d5db; */
    border-bottom: 1px solid #d1d5db;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 6px;
    outline-color: transparent;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
  }

  .p-dropdown .p-dropdown-label.p-placeholder {
    color: lightgray;
  }

  input[type="text"]:focus {
    border: none !important;
    box-shadow: none !important;
  }
}

/* .p-dialog-mask .p-dialog .p-dialog-header {
    padding: 0.6rem 1rem !important;
}

.p-dialog .p-dialog-content {
    padding: 0 1rem 0.6rem 1rem;
} */

.circle {
  width: 50px;
  height: 50px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebeff4;
  color: gray;
  border-radius: 100%;
  text-transform: uppercase;
}

.circle_profile {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

/* {{{{{{{{{{{{{{{{{{{{{{{Globalsankbar css}}}}}}}}}}}}}}}}}}}}}}} */
.snakbar {
  .p-toast-detail {
    margin-top: 0;
  }

  .p-toast-message-text,
  .p-toast-message-content {
    display: flex;
    align-items: center;
  }

  .p-toast .p-toast-message {
    margin-top: 2.5rem;
  }
}

/* {{{{{{{{{{{{{{{{{{{{{{{Globalsankbar css}}}}}}}}}}}}}}}}}}}}}}} */
.treeSelect {
  .search-wrapper {
    position: relative;
  }

  .search-icon {
    position: absolute;
    z-index: 111;
    font-size: 20px;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #525252;
    pointer-events: none;
  }
}

.custom-datatable-daily-task .p-datatable-tbody>tr {
  border-bottom: 1px solid #cccccc;
}

/* .custom-datatable-daily-task .p-datatable-thead > tr {
  display: none;
} */

.slider-image {
  width: 100%;
  /* Makes image take full width of the slider */
  height: 100%;
  /* Keeps image aspect ratio intact */
  object-fit: contain;
  /* Ensures the entire image fits within the slider */
}

/* {{{{{{{{{{{{{{{{{{{{{{{popover css}}}}}}}}}}}}}}}}}}}}}}} */

.customPopover1 {
  margin: 0 !important;
  border-radius: 4px !important;
  overflow: auto;

  .p-overlaypanel-content {
    padding: 0 !important;
  }
}

.customPopover1:before {
  display: none !important;
  margin: 0 !important;
}

.popoverListing {
  hr {
    margin: 0;
  }

  ul {
    padding: 4px;
    margin: 0;
    border-radius: none;
  }

  li {
    padding: 0.6rem 1.4rem;
  }

  li:hover {
    background: #dddcdc;
    cursor: pointer;
  }
}

/**************************************************************** STYLE FOR SCROLLBAR ***************************************************************/

/* WebKit Browsers (Chrome, Safari) */
/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
  /* Width of the vertical scrollbar */
  height: 8px;
  /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Grey background for the scrollbar track */
  border-radius: 10px;
  /* Rounded corners for the track */
}

::-webkit-scrollbar-thumb {
  background: #888;
  /* Darker grey color for the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Darker grey color when hovered */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  /* Thin scrollbar */
  scrollbar-color: #888 #f1f1f1;
  /* Colors for thumb and track */
}

.grid1 {
  display: grid;
  grid-template-columns: 100%;
}

.grid2 {
  display: grid;
  grid-template-columns: 49% 49%;
  justify-content: space-between;
}

.grid3 {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;
}

.grid4 {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  justify-content: space-between;
}

.deleteConfirm_secondary_btn {
  font-family: Inter;
  font-size: 16px;
  border: none;
  background: white;
  font-weight: 500;
  line-height: 28px;
  text-align: left;

}

.deleteconfirm_image_con {
  /* padding-top: 18px; */
  width: 400px;
}

.completedProject {
  background-color: #CDFFCD !important;
  color: #007F00
}

.completedProjectdot {
  background-color: #007F00;
}

.activeProject {
  background-color: rgba(154, 225, 255, 1) !important;
  color: #00587E
}

.activeProjectdot {
  background-color: #00587E;
}

.onholdProject {
  background-color: #FFECCC !important;
  color: #965E00
}

.onholdProjectdot {
  background-color: #965E00;
}


.project_status_container {

  .project_status_item_con {
    padding: 3px 10px;
    /* height: 20.11px; */
    width: fit-content;
    text-transform: capitalize;
    border-radius: 2px;
    font-family: Inter;
    font-size: 12.687px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    .project_status_dot {
      border-radius: 50%;
      width: 6.607px;
      height: 6.35px;
    }
  }
}
@media (max-width: 570px) {
  .deleteconfirm_image_con{
    width: 250px;
  }
}