.tabCard_container {
    cursor: pointer;
    width: 100%;
    border-radius: 11px;
    background-color: rgba(238, 238, 238, 0.95);
    padding: 7px 15px 20px 15px;
    height: 96px;

    /* Vendor prefixes */
    -webkit-border-radius: 11px;
    /* Safari and Chrome */
    -moz-border-radius: 11px;
    /* Firefox */
    -ms-border-radius: 11px;
    /* Internet Explorer 9 */
    -o-border-radius: 11px;
    /* Opera */





    .tabCard_header {
        color: #7E7E7E;
        font-family: Inter;
        font-size: 18.204px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.182px;
    }

    .tabCard_value {
        color: #7E7E7E;
        font-family: Inter;
        font-size: 41.1px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 8px;
    }
}

.tabCard_activeCard {
    background-color: rgba(255, 123, 0, 1);

    .tabCard_header {
        color: white;
    }

    .tabCard_value {
        color: white;
    }
}

.tabCard_smallCard {
    height: 38px !important;

}

.userProfile_container {

    display: flex;
    flex-direction: column;
    align-items: center;

    .userImage_con {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .userImage_dotted_border_img {
            width: 107px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-image: url("/public/images/Profile\ Level\ definer.png");
            height: 107px;
            border-radius: 50%;
            position: absolute;
            top: 3px;
        }

        .userImage_edit_con {
            position: absolute;
            top: 35px;
            font-size: 20px;
            cursor: pointer;
            color: rgba(0, 0, 0, 1);
            right: -20px;
        }

        .userImage_edit_con2 {
            position: absolute;
            top: 0px;
            font-size: 25px;
            cursor: pointer;
            color: rgba(0, 0, 0, 1);
            right: -10px;
            color: rgba(249, 123, 6, 1)
        }

        .userProfile_img {
            width: 83px;
            height: 83px;
            /* fill: linear-gradient(180deg, #FDFDFD 6.9%, #E1E2F6 100%);
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 50%;
            overflow: hidden;
            background: linear-gradient(to bottom, #FDFDFD, #E1E2F6);

        }
    }

    .userProfile_userName {
        color: #4F4E4E;
        font-family: Inter;
        font-size: 28.11px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: rgba(79, 78, 78, 1)
    }

    .userProfile_userName2 {
        color: #000;
        font-size: 15.328px;
        font-weight: 700;
        line-height: normal;
    }

    .userProfile_userEmail {
        color: #F87B06;
        font-family: Inter;
        font-size: 12.11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    }

    .userProfile_logoutBtn {
        color: #F97B06;
        font-family: Inter;
        font-size: 17.139px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.343px;
        margin-top: 20px;

    }

    .userProfile_userRole {
        color: #6B6B6B;
        font-family: Inter;
        font-size: 12.827px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.257px;
    }

}

@media (max-width: 570px) {
    .userProfile_container {
        margin-top: 2rem;
    }

    .tabCard_smallCard {
        height: unset !important;

    }
}

.pdf-header-text {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 29px;
    text-align: right;
    color: #101928;
}

.pdf-header-project-name {
    font-weight: 700;
}

.pdf_logo {
    height: 30px;
}

.pdf_footer_item {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 26.1px;
    text-align: right;
    color: #7B7C80;
}