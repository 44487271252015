/* .chartvarr{
    width: 703px;
    height: 253px;
    align-items: center !important;
    justify-content: center !important;

} */


th {
    border-bottom: 5px solid black;
}
.paint-touch{
    background: white;
    p{
        margin: 0 !important;
    }

    gap: 35px;
    .paint{
        display: flex;
        gap: 8px;
        text-decoration: none;
        color: black;
    }
 
    .heading {
        font-size: 24px !important;
        font-weight: 600 !important;
        letter-spacing: -2%;
        line-height: 28.8px;
        color: 
        #000000;
    }
    .ready-para::before{
        width: 15px;
        height: 15px;
        background-color: green;
    }
    .ready-para{
        margin-top: 12px !important;
        font-size: 18px;
        font-weight: 500;
        line-height: 26.1px;
        margin-bottom: 12px !important;
        color:   #101928;
    }
    .table-one{
        display: flex;
        flex-direction: row;
       gap: 20px;
    }
    .left-side{
        font-size: 16px;
        font-weight: 500;
        line-height: 23.2px;
        color: #475367;
        font-family: "Inter", sans-serif;

    }
    .right-side{
        font-size: 16px;
        font-weight: 500;
        line-height: 23.2px;
        color: #101928;
    }
    .left-side1{
        color: #475367;
        font-size: 14px;
        font-weight: 400;
        line-height: 20.3px;
        padding: 6px;
        
    }
    
    .right-side1{
        color:#101928;
        font-size: 14px;
        font-weight: 400;
        line-height: 20.3px;
        padding: 6px;
        
    }
    .none{
        color: #98A2B3;
    }
    .assignees{
        font-size: 18px;
        font-weight: 600;
        line-height: 26.1px;
        color: #101928;
        margin-bottom: 16px;

    }
   
    .table{
        margin: 5px;
        
    }
    hr{
        width: 100%;
        /* margin-top: 2%; */
        /* margin-bottom: 2%; */
    }
    
 
 


   
    }
    
    .drag-css{
        .p-inputtext {
            border-style: dashed solid !important;
            width: 100% !important;
            font-family: var(--font-family);
            font-feature-settings: var(--font-feature-settings, normal);
            font-size: 1rem;
            color: #4b5563;
            background: #ffffff;
            padding: 0.75rem 0.75rem;
        
            transition: background-color 0.2s, color 0.2s, border-color 0.2s,
              box-shadow 0.2s;
            appearance: none;
            border-radius: 6px;
          }
    }
    .pop-css{
        .label{
            font-size: 15px !important;
           color: #101928;
          }
       
          .input:focus{
            outline: none;
            border: 1px solid grey;
            box-shadow: none !important;
          }
        
      
         .p-dropdown:focus{
            border: 1px solid grey;
         }
        
    }

   .dialog{
    
    .labelCss {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-top: 5px;
        color: #101928 !important;
      }
    .header_border{
        border-bottom: 1px solid lightgray;
    }
    .attach-file{
        text-decoration: underline !important;
        
      }
   }
   .click-able{
    cursor: pointer;
    text-decoration: none;
    color: #f97b06 !important;
    margin-top: 3px;
  
    

    .labelCss {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #101928 !important;
      }
    
}

.dialog{

.btn_main {
    display: flex;
    justify-content: end;
    gap: 10px;
    align-items: center;
    margin-top: 10px;
  }

  .CreateRfi {
    padding: 8px, 12px, 8px, 12px;
    color: white;
    background-color: #f97b06 !important;
    border-radius: 8px;
    cursor: pointer;
  }

  .cancelBtn {
    color: black;
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #d0d5dd;
  }
}
 
 
   

    
@media only screen and (max-width: 600px) {
    .ButtonAddTem{
       font-size: 10px !important;
    }
    .p-tabview-panels{
      padding: 0;
    }
    }