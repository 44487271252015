.labelCss {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #101928 !important;
  }
  @media only screen and (max-width: 500px) {
    .end{
    display: flex;
    justify-content: center;
     
    }
    }