.primary_button_con {
  border: none;
  border-radius: 10.03px;
  background: #FF7B00;
  padding: 4px 12px;
  color: #FFF;
  font-family: Inter;
  font-size: 17.28px;
  font-style: normal;
  font-weight: 700;

}
.primary_button_con:disabled{
  background-color: #fcc48f;
}





.deleteButton {
  all: unset;
  color: #f97b06;
  cursor: pointer;
  font-size: 20px;
}
.editButton {
  all: unset;
  cursor: pointer;
  color: #f97b06;
  font-size: 18px;
}

.export_button_con {
  border: none;
  background-color: #ffffff !important;
  color: #101828;
}
.p-dropdown .p-dropdown-trigger {
  width: 1rem !important;
}
.export_button_sec {
  .p-dropdown .p-dropdown-label.p-placeholder {
    color: #ffffff !important;
  }
  .p-dropdown .p-dropdown-trigger {
    color: #ffffff !important;
  }
}
.export_button {
 margin-left: 1px;
 padding-left: 10px;
}
.add_icon {
  position: absolute;
  top: 13px;
  left: 2px;
}
.pdf_dropDown{
  
  height: 2.6rem;
  padding: 0px;
  display: flex !important;
  align-items: center !important;
}
@media (max-width: 570px) {
  .primary_button_con{
    font-size: 14px !important;
  }

}
