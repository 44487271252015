.portfoli_mainn {
  /* padding: 0px 40px; */

  .input_box {
    width: 375px;
    height: 36px;
    padding: 8px, 12px, 8px, 12px;
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    padding-left: 35px;
  }

  /* .p-column-header-content {
    justify-content: center !important;
  } */

  .filter_Button {
    height: 36px;
    padding: 8px, 8px, 16px, 16px !important;
    border-radius: 8px;

    gap: 10px;
    color: #344054;
    text-align: center;
    background: transparent;
  }

  .side-button1 {
    background-color: #f3a218;
    font-size: 18px !important;
  }

  .side-button2 {
    font-size: 18px;
  }

  .search_icon {
    left: 15px;
    top: 12px;
  }

  .chart_head {
    background: #012a3e;
    padding: 20px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }

  .chart_heading {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }

  .card {
    margin-top: 2px;
  }

  .statusBox {
    width: 1rem;
    height: 1rem;
  }

  .red {
    background: red;
  }

  .green {
    background: green;
  }

  .yellow {
    background: #f3a218;
  }

  .primary-button {
    justify-content: space-between;
  }

  .heading {
    justify-content: space-between;
  }
  .side-text {
    margin-top: 25px;
    text-align: right;
    justify-content: space-between;
    display: flex;
    margin-left: 5px;
    gap: 20px;
  }
  .box2 {
    align-items: center;
    display: flex;
    justify-content: end;
    gap: 10px;
  }
  .ms-2{
    font-size: 14px;
    font-family: sans-serif;
  }

    
  }


.w-10{
  width: 8%;
  min-width: 110px;
}
.w-90{
  width: 90%;
}

.gray-lt{
  color: #475367;
  font-weight: 500;
}
.gray-ult{
  color: #98A2B3;
  font-weight: 500;
}
.gray-d{
  color:   #101928;
  font-weight: 500;
}

.table-data-class{
  padding: 1rem;
  font-size: 14px !important;
  border: 0.5px solid #81828336;
  /* border: 1px solid #81828336; */
}

.table-class-rfi{
  border: 0.5px solid #81828336;
}

@media screen and (max-width: 900px) {
  .genralInfoRfi{
    .box{
      width: 100% !important;
    }
  }

}

