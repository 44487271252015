.Topbar {
    .Tob_bar_section {

        height: 64px;
        padding-left: 32px;
        padding-Right: 32px;
        background-color: red;
        /* background-color: #FFFFFF; */
        /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */

        padding-top: 20px;
        padding-bottom: 15px;

    }

    .line_icon {
        color: #D0D5DD;
        width: 24px;
border: 1px solid #D0D5DD;  
rotate: 90deg;



    }

    .cdc {

        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;

    }

    .dropdown_box {
        width: 200px;
        /* height: 25px; */
        padding: 8px, 16px, 8px, 16px;
        border-radius: 6px;
        border: 1px;
        gap: 4px;
        color: #344054;


    }

    .p-dropdown-items {
        border: 1ps solid red !important;
        
    }

    .right_sec li {
        list-style: none;

    }

    .right_sec {
        gap: 20px;
    }

    .card{
        border: 1px solid #D0D5DD !important;
    }
}

.p-dropdown-items-wrapper{
    .p-dropdown-panel .p-dropdown-items {
        padding: 0 !important;
    }

    ul{
        padding: 0 !important;
    }

    scrollbar-width: none;
}

.nav234{
    position: absolute;
    right: 2%;

    margin-top: 15px;
    top: 0%;
    
}
.nav234 ul {
    list-style-type: none !important; /* This will change the list items to dots */
  }
  @media only screen and (max-width: 993px) {

    .nav234{
        position: absolute;
        right: 10%;
         margin-top: 8px;
        
    }
  }
  @media only screen and (max-width: 730px) {
   
    .nav234{
        position: absolute;
        right: 15%;
        /* top: 10%; */
        
    }
  }
  @media only screen and (max-width: 500px) {
 
    .nav234{
        position: absolute;
        right: 22%;
        /* top: 12%; */
        
    }
  }