.main_container {
  height: 100%;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.main_container_upper {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: stretch;
}

/* Set equal flex values for consistent width */
.main_container_upper_left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 2;
}


.main_container_upper_left_upper,
.main_container_upper_left_lower {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}


.main_container_upper_left_upper_left,
.main_container_upper_left_upper_right,
.main_container_upper_left_lower_left,
.main_container_upper_left_lower_right {
  background: white;
  border-radius: 12px;
  box-shadow: 4px 4px 20px 0px #0000000a;
  padding: 1rem;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.1px;
  text-align: left;
  flex: 1;
  width: 50%;
}


.main_container_upper_right {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
  border: 1px solid red;
}

.main_container_upper_right_upper {
  background: white;
  border-radius: 12px;
  box-shadow: 4px 4px 20px 0px #0000000a;
  padding: 1rem;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.1px;
  text-align: left;
  flex: 1;
  /* width: 50%; */
}

.main_container_upper_right_lower {
  background: white;
  border-radius: 12px;
  box-shadow: 4px 4px 20px 0px #0000000a;
  padding: 1rem;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.1px;
  text-align: left;
  flex: 3;
}





.main_container_lower {
  display: flex;
  flex-direction: column;
  gap: 2rem;

}

.main_container_lower_lower,
.main_container_lower_upper {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.main_container_lower_lower_left1{
  background: white;
  border-radius: 12px;
  box-shadow: 4px 4px 20px 0px #0000000a;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.1px;
  text-align: left;
  flex: 1;
  /* width: 50%;
  height: 50vh; */
  overflow:hidden;
}
.main_container_lower_upper_left,
.main_container_lower_upper_right,
.main_container_lower_lower_left,
.main_container_lower_lower_right {
  background: white;
  border-radius: 12px;
  box-shadow: 4px 4px 20px 0px #0000000a;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.1px;
  text-align: left;
  flex: 1;
  width: 50%;
  height: 50vh;
  overflow:hidden;
}

.main_container_lower_upper_left,.main_container_lower_lower_right {
  
  overflow: auto;
}

.main_container_lower_upper_left_inner_container,.main_container_lower_lower_right_inner_container {

  height: 80%;
  overflow: auto;
  overflow-x: auto;

}

.main_container_lower_upper_right,
.main_container_lower_lower_left {
  /* margin: 0;  */
  position: relative;

}

.main_container_lower_upper_right_inner_container,
.main_container_lower_lower_left_inner_container {
  border-radius: 12px;
  overflow: hidden;
  height: 50vh;
  width: 100%;
}



.main_container_lower_upper_right_heading,
.main_container_lower_lower_left_heading {

  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.1px;
  text-align: left;
  display: flex;

  position: absolute;
  z-index: 999;
  top: 2%;
  left: 2%;
}














@media only screen and (max-width: 860px) {

  .main_container_upper {
    flex-direction: column;

  }

  .main_container_upper_left_upper,
  .main_container_upper_left_lower {

    flex-direction: column;

  }

  .main_container_upper_left_upper_left,
  .main_container_upper_left_upper_right,
  .main_container_upper_left_lower_left,
  .main_container_upper_left_lower_right {

    width: 100%;
  }

  .main_container_lower_lower,
  .main_container_lower_upper {
    flex-direction: column;

  }

  .main_container_lower_upper_left,
  .main_container_lower_upper_right,
  .main_container_lower_lower_left,
  .main_container_lower_lower_right {

    width: 100%;
  }

  .main_container_lower_lower_left_inner_container
  {
    height: unset;
  }
}