.dailyLog {
    .parent {
        /* margin: 2.5rem 2rem; */

        .body {
            gap: 40px;
            background: white;
            border-radius: 1rem;
            /* padding: 1.5rem; */
        }
        .logName{
            font-weight: 700;
            
            
        }

        .header {
            gap: 1.5rem;

            .right {
                font-size: 0.9rem;
            }

        }

        .logTable {
            gap: 1rem;

            .react_table {
                .p-column-header-content {
                    .p-checkbox {
                        display: none;
                    }
                }

                /* .p-datatable .p-datatable-tbody > tr.p-highlight {
                    background: white;
                    color: #000000;
                } */

                .delete{
                    color: #F97B06;
                }

                .blank{
                    color: #98A2B3;
                }
            }
        }          


    }
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow:0 0 0 0.2rem #a5f3fc00;
    border-color: none;
}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: none;
}
@media only screen and (max-width: 500px) {
    .SearchInputC{
      margin-bottom: 20px !important;
     
    }
    .dailyLog{
         .parent {
            /* margin: 5px; */
            .body{
                /* padding: 10px; */
                background: white;
                border-radius: 1rem;
            }
        }
    }
    }
.table_scroll_daily_log{
    height: calc(100vh - 370px);
    overflow: auto;
}