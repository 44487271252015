.Project_home_Section {

    background-color: #ffff;
    margin: 40px 32px;
    padding: 24px;
    border-radius: 20px;

    .sub_text {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;

    }

    .chart_total_home {
        width: 5rem;
        gap: 20px !important;
    }

    .horizontalGraph {
        width: calc(100% - 5rem);
    }

    .GapChart {
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-top: 20px;
    }


}

.navbar-toggler:focus{
    box-shadow: none !important;
}

@media only screen and (max-width: 600px) {
    .heading {
        /* background-color: red !important; */
        font-family: Inter;
        font-size: 18px !important;
        display: flex;
        align-items: center;
        line-height: 29px;
        letter-spacing: -0.02em;
        text-align: left;
    }

    .Project_home_Section {
        margin: 0px 15px;
        padding: 0;
    }

    .TotalOpen {
        font-size: 13px;
        padding-right: 15px;
    }

    .totalOpen {
        display: flex;
        justify-content: center;
        gap: 10px;
    }

    .GapChart {
        margin-top: 45px !important;
        gap: 19px !important;
    }
}