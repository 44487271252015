.p-menuitem-link {
  color: red !important;
}
a {
  /* color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)); */
  color: #344054 !important;
  text-decoration: none !important;
}

.p-menubar {
  background: none !important;
  background-color: none !important;
  border: none !important;
}
.p-menuitem-link {
  border-color: orange !important;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border-color: orange !important;
}
.p-tabmenu.p-tabmenu-nav.p-tabmenuitem.p-highlight.p-menuitem-link {
  border-color: 1px solid red !important;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff !important;
  border-color: #f97316 !important;
  color: #e4eced !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border-color: #f97316 !important;
}
.open_Rfi_text {
  font-size: 0.8rem;

  color: #98a2b3;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}

.createNew_text {
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 20px;
  color: #012a3e;
  cursor: pointer;
}

.newRfi_text {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 28px;
  color: #000000;
}

.addCompany {
  font-size: 0.9rem;
  font-weight: 600;
  color: #344054;
  border: 0.5px solid #d0d5dd;
  background: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.editDelete {
  color: black;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 12px;
}


.labelCss {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #101928 !important;

}
@media only screen and (max-width: 600px) {
.ButtonAddTem{
   font-size: 10px !important;
}
}