.lightShadowCard_container {
    border-radius: 11.795px;
    background: #FFF;
    box-shadow: 32.437px 3.932px 19.659px 0px rgba(0, 0, 0, 0.04);
    padding: 15.73px;
    transition-property: all 1s linear;

    .card_header {
        margin-bottom: 13.76px
    }
}

.lightShadowCard_container:hover {
    box-shadow: 3.932px 3.932px 19.659px 0px rgba(0, 0, 0, 0.31);
}