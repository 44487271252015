.Submittal_section{
    border-radius: 20px;
    .pageOf{
  
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        
        }
        .colorbox{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .angle_icon{
            /* width: 10px;
        height: 5px; */
        
        /* angle: -90 deg; */
        color: #667185;
        
        }
        .p-column-header-content {
            .p-checkbox {
                display: none;
            }
        }
        .cHARTgAP{
            display: flex;
            justify-content: end;
            align-items: center;
            gap: 15px;
        }

        
}
@media only screen and (max-width: 600px) {
    .SearchInputC{
      margin-bottom: 20px !important;
     
    }
 
    .colorbox{
        /* display: flex; */
        justify-content: center;
    }
    .cHARTgAP{
        display: flex !important;
        justify-content: center !important;
    }
    }


    .maindiv_plusIcon{
        width: 98% !important;
    }