.transmittals{
    .p-tabview .p-tabview-panels {
       
        padding: 0;
       
    }
}
.create-trans{
    .retainage{
        width: 5rem !important;
    }
    .ml-2{
        font-size: 14px;
        margin-left: 5px;
    }
   
}
