.loader-container {
    display: flex;
    justify-content: center; /* horizontally center */
    align-items: center; /* vertically center */
    height: 70vh; /* or any desired height */
}

.loader {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: block;
    position: relative;
    background: #FFF;
    box-shadow: -24px 0 #FFF, 24px 0 #FFF;
    box-sizing: border-box;
    animation: shadowPulse 2s linear infinite;
}

  
  @keyframes shadowPulse {
    33% {
      background: #FFF;
      box-shadow: -24px 0 #F97B06, 24px 0 #FFF;
    }
    66% {
      background: #F97B06;
      box-shadow: -24px 0 #FFF, 24px 0 #FFF;
    }
    100% {
      background: #FFF;
      box-shadow: -24px 0 #FFF, 24px 0 #F97B06;
    }
  }