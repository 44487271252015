.createHeadNameBtn {
  color: white;
  border-radius: 5px;
  padding: 154px;
  text-align: center;
  background: #f97b06;
  cursor: pointer;
}
@media (max-width: 999px) {
  .createHeadNameBtn {
    font-size: 0.8rem !important;
  }
}
